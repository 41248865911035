<div class="w-[334px] bg-white text-dark-green py-4 px-8 rounded-[30px]">
  <div
    class="flex justify-center items-center gap-3 mb-4"
    *ngIf="title"
    [class]="options.titleClass"
    [attr.aria-label]="title"
  >
    <span
      *ngIf="
        toastClasses.includes('toast-success') || title.includes('Επιτυχία')
      "
    >
      <ng-container [ngTemplateOutlet]="successIcon"></ng-container>
    </span>
    <span
      *ngIf="toastClasses.includes('toast-error') || title.includes('Αποτυχία')"
    >
      <ng-container [ngTemplateOutlet]="errorIcon"></ng-container>
    </span>
    <span class="flex-grow">{{ title }}</span>
    <button class="cursor-pointer" (click)="remove()">
      <ng-container [ngTemplateOutlet]="closeIcon"></ng-container>
    </button>
  </div>
  <div
    *ngIf="message && options.enableHtml"
    role="alert"
    [class]="options.messageClass"
    [innerHTML]="message"
  ></div>
  <div
    *ngIf="message && !options.enableHtml"
    role="alert"
    [class]="options.messageClass"
    [attr.aria-label]="message"
  >
    {{ message }}
  </div>
</div>

<ng-template #closeIcon>
  <svg
    width="13"
    height="13"
    viewBox="0 0 13 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.20515 6.46153L12.1139 1.25553C12.2375 1.11358 12.3045 0.926374 12.3009 0.733164C12.2973 0.539955 12.2233 0.355727 12.0945 0.21908C11.9657 0.0824338 11.792 0.00397886 11.6098 0.000147219C11.4276 -0.00368443 11.2511 0.0674054 11.1172 0.198527L6.13213 5.40454L1.22338 0.198527C1.08004 0.071688 0.895682 0.00918925 0.709544 0.0243171C0.523406 0.039445 0.350189 0.131014 0.226742 0.279536C0.115086 0.414525 0.0535164 0.587958 0.0535164 0.767527C0.0535164 0.947096 0.115086 1.12054 0.226742 1.25553L5.13548 6.46153L0.226742 11.6675C0.156976 11.7359 0.100943 11.8185 0.062005 11.9103C0.0230667 12.0022 0.00202178 12.1015 0.000138671 12.2022C-0.00174444 12.3029 0.0155685 12.4029 0.0510417 12.4964C0.086515 12.5898 0.139414 12.6747 0.206571 12.7459C0.273728 12.8171 0.353764 12.8732 0.441864 12.9109C0.529965 12.9485 0.624315 12.9669 0.71927 12.9649C0.814225 12.9629 0.907821 12.9405 0.994446 12.8992C1.08107 12.8579 1.15894 12.7985 1.22338 12.7245L6.13213 7.51853L11.0409 12.7245C11.1053 12.7985 11.1832 12.8579 11.2698 12.8992C11.3564 12.9405 11.45 12.9629 11.545 12.9649C11.6399 12.9669 11.7343 12.9485 11.8224 12.9109C11.9105 12.8732 11.9905 12.8171 12.0577 12.7459C12.1248 12.6747 12.1777 12.5898 12.2132 12.4964C12.2487 12.4029 12.266 12.3029 12.2641 12.2022C12.2622 12.1015 12.2412 12.0022 12.2023 11.9103C12.1633 11.8185 12.1073 11.7359 12.0375 11.6675L7.20515 6.46153Z"
      fill="#003333"
      fill-opacity="0.5"
    />
  </svg>
</ng-template>

<ng-template #successIcon>
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.702 0.237128L8.49184 10.671L5.1432 6.88187C4.58916 6.25444 3.71665 7.20681 4.26871 7.83528L8.08259 12.1504C8.14079 12.2102 8.21064 12.2576 8.28783 12.2894C8.36502 12.3212 8.44791 12.3369 8.53139 12.3354C8.61487 12.334 8.69717 12.3154 8.7732 12.2809C8.84924 12.2464 8.91739 12.1967 8.97346 12.1348L17.6102 1.15942C18.1249 0.505335 17.2206 -0.422023 16.702 0.237128Z"
      fill="#003333"
    />
    <path
      d="M15.3782 6.45328C15.9306 8.12008 15.8736 9.92886 15.2173 11.5576C14.561 13.1863 13.3479 14.5292 11.7942 15.3473C10.3518 16.0672 8.71173 16.2895 7.12978 15.9795C5.54783 15.6694 4.113 14.8445 3.04916 13.6333C1.90932 12.3312 1.27054 10.6656 1.24733 8.93523C1.22411 7.20485 1.81798 5.52277 2.92248 4.19054C4.02698 2.8583 5.56988 1.96302 7.2746 1.66519C8.97931 1.36737 10.7344 1.68646 12.2252 2.56528C12.3695 2.64033 12.5372 2.65693 12.6934 2.61164C12.8497 2.56634 12.9825 2.46263 13.0643 2.32202C13.1461 2.18141 13.1707 2.0147 13.1328 1.85648C13.095 1.69826 12.9977 1.56067 12.8612 1.47227C6.92916 -1.86673 -0.690835 2.73327 0.0501651 9.71227C0.218015 11.196 0.770074 12.6102 1.6518 13.8153C2.53353 15.0203 3.7144 15.9745 5.07775 16.5834C6.44111 17.1924 7.93975 17.4351 9.42562 17.2876C10.9115 17.1401 12.3332 16.6074 13.5502 15.7423C14.9961 14.6542 16.0668 13.1423 16.6131 11.4171C17.1595 9.69195 17.1542 7.83934 16.5982 6.11728C16.3532 5.34128 15.1372 5.67228 15.3782 6.45328Z"
      fill="#99FF66"
    />
  </svg>
</ng-template>

<ng-template #errorIcon>
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.98153 17.0476C13.5919 17.0476 17.3293 13.442 17.3293 8.99436C17.3293 4.5467 13.5919 0.941162 8.98153 0.941162C4.3712 0.941162 0.633789 4.5467 0.633789 8.99436C0.633789 13.442 4.3712 17.0476 8.98153 17.0476Z"
      stroke="#D67272"
      stroke-width="1.2"
    />
    <path
      d="M9.63815 8.24833H13.0977C13.4147 8.24833 13.6747 8.58269 13.6747 8.99453C13.6747 9.40636 13.4168 9.74072 13.0977 9.74072H5.0247C4.70558 9.74072 4.44775 9.40636 4.44775 8.99453C4.44775 8.58269 4.70558 8.24833 5.0247 8.24833H9.63815Z"
      fill="#D67272"
    />
  </svg>
</ng-template>
