import { CommonModule } from "@angular/common"
import { Component } from "@angular/core"
import { AboutUsComponent } from "./components/about-us/about-us.component"
import { ClientsComponent } from "./components/carousels/owl/clients/clients.component"
import { EarlyAccessComponent } from "./components/early-access/early-access.component"
import { FeaturesComponent } from "./components/features/features.component"
import { FooterComponent } from "./components/footer/footer.component"
import { MyDataComponent } from "./components/my-data/my-data.component"
import { NavbarComponent } from "./components/navbar/navbar.component"
import { PricingComponent } from "./components/pricing/pricing.component"
import { WelcomeComponent } from "./components/welcome/welcome.component"
import { WorkflowComponent } from "./components/workflow/workflow.component"
import { LoadingService } from "./services/loading/loading.service"

@Component({
  selector: "app-root",
  standalone: true,
  imports: [
    CommonModule,
    NavbarComponent,
    WelcomeComponent,
    WorkflowComponent,
    FeaturesComponent,
    PricingComponent,
    MyDataComponent,
    EarlyAccessComponent,
    AboutUsComponent,
    FooterComponent,
    ClientsComponent,
  ],
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"],
})
export class AppComponent {
  loading$ = this.loadingService.loading$

  constructor(private loadingService: LoadingService) // private meta: Meta,
  // @Inject(PLATFORM_ID) private platformId: Object,
  {
    // this.setOgImage()
  }

  // private setOgImage() {
  //   const imageRelativePath =
  //     "assets/favicon/Camprrr_Logo_Round_rrr_solid_mix_dark.svg"
  //   let fullImageUrl: string

  //   if (isPlatformBrowser(this.platformId)) {
  //     fullImageUrl = `${window.location.origin}${imageRelativePath}`
  //   } else {
  //     // For server-side rendering, you might want to set a default or
  //     // inject a configuration service to get the deployed URL
  //     fullImageUrl = `https://camprrr.site/${imageRelativePath}`
  //   }

  //   this.meta.updateTag({ property: "og:image", content: fullImageUrl })
  // }
}
