import { Component } from "@angular/core"
import { CommonModule } from "@angular/common"
import { NgOptimizedImage } from "@angular/common"
import { TranslateModule, TranslateService } from "@ngx-translate/core"
import { AnimationOptions, LottieComponent } from "ngx-lottie"

@Component({
  selector: "app-workflow",
  standalone: true,
  imports: [CommonModule, NgOptimizedImage, TranslateModule, LottieComponent],
  templateUrl: "./workflow.component.html",
  styleUrls: ["./workflow.component.css"],
})
export class WorkflowComponent {
  flowItems: FlowItem[] = [
    {
      uuid: "be893359-afa5-4ca0-84bc-6748e566947c",
      text: this.translate.instant("workflow.without_cost"),
    },
    {
      uuid: "8101472b-5c48-41ec-a83a-bb383d93c115",
      text: this.translate.instant("workflow.every_device"),
    },
    {
      uuid: "205b638e-2700-4ba9-abb6-cde6482d6856",
      text: this.translate.instant("workflow.auto_emails"),
    },
  ]
  constructor(private translate: TranslateService) {}

  options: AnimationOptions = {
    loop: true,
    autoplay: true,
    path: "assets/animations/desk.json",
  }

  trackByFn(index: number, item: FlowItem) {
    return item.uuid
  }
}

type FlowItem = {
  uuid: string
  text: string
}
