import {
  Component,
  OnInit,
  Directive,
  ElementRef,
  Output,
  EventEmitter,
  HostListener,
} from "@angular/core"
import { CommonModule } from "@angular/common"
import { NgxSmartModalModule, NgxSmartModalService } from "ngx-smart-modal"
import { RequestFormComponent } from "../request-form/request-form.component"
import { TranslateModule, TranslateService } from "@ngx-translate/core"
import { TranslationService } from "src/app/services/translation/translation.service"

@Directive({
  selector: "[clickOutside]",
  standalone: true,
})
export class ClickOutsideDirective {
  @Output() clickOutside = new EventEmitter<void>()

  constructor(private elementRef: ElementRef) {}

  @HostListener("document:click", ["$event.target"])
  public onClick(target: any) {
    const clickedInside = this.elementRef.nativeElement.contains(target)
    if (!clickedInside) {
      this.clickOutside.emit()
    }
  }
}

@Component({
  selector: "app-navbar",
  standalone: true,
  imports: [
    CommonModule,
    NgxSmartModalModule,
    RequestFormComponent,
    TranslateModule,
    ClickOutsideDirective,
  ],
  templateUrl: "./navbar.component.html",
  styleUrls: ["./navbar.component.css"],
})
export class NavbarComponent implements OnInit {
  transparentNav?: boolean
  currentLang = "en"
  isDropdownOpen = false
  languages = ["en", "el"]

  constructor(
    public ngxSmartModalService: NgxSmartModalService,
    private translate: TranslateService,
    private translationService: TranslationService,
  ) {}

  ngOnInit() {
    this.translationService.getCurrentLang().subscribe((lang) => {
      this.currentLang = lang
    })
  }

  toggleLanguage() {
    this.isDropdownOpen = !this.isDropdownOpen
  }

  selectLanguage(lang: string) {
    if (lang !== this.currentLang) {
      this.translationService.setLanguage(lang)
      this.currentLang = lang
    }
    this.isDropdownOpen = false
  }

  getOtherLanguage(): string {
    return (
      this.languages.find((lang) => lang !== this.currentLang) ||
      this.languages[0]
    )
  }

  getLanguageLabel(lang: string): string {
    return this.translate.instant(`navbar.${lang}`)
  }

  onClickOutside() {
    this.isDropdownOpen = false
  }
}
