import { Component } from "@angular/core"
import { CommonModule } from "@angular/common"
import { TranslateModule, TranslateService } from "@ngx-translate/core"
import { NgxSmartModalModule, NgxSmartModalService } from "ngx-smart-modal"
import { RequestFormComponent } from "../request-form/request-form.component"

@Component({
  selector: "app-pricing",
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    NgxSmartModalModule,
    RequestFormComponent,
  ],
  templateUrl: "./pricing.component.html",
  styleUrls: ["./pricing.component.css"],
})
export class PricingComponent {
  plans: Plan[] = [
    {
      uuid: "a147f023-dca9-4acf-8de2-e74c248e0280",
      title: this.translate.instant("pricing.mini"),
      subTitle: "",
      features: [
        this.translate.instant("pricing.reservation_management"),
        this.translate.instant("pricing.calendar"),
        this.translate.instant("pricing.timeline_view"),
        this.translate.instant("pricing.spots_plan"),
        this.translate.instant("pricing.reports"),
      ],
      price: "800",
    },
    {
      uuid: "d16ca966-8aff-459e-8c75-4cd6e870c05d",
      title: this.translate.instant("pricing.standard"),
      subTitle: this.translate.instant("pricing.everything_included_mini"),
      features: [
        this.translate.instant("pricing.booking_form"),
        this.translate.instant("pricing.advanced_reports"),
        this.translate.instant("pricing.campsite_customization"),
        this.translate.instant("pricing.welcome_message"),
        this.translate.instant("pricing.customer_archive"),
        this.translate.instant("pricing.map_of_your_campsite"),
      ],
      price: "1500",
    },
  ]
  constructor(private translate: TranslateService, public ngxSmartModalService: NgxSmartModalService) {}

  trackByFn(index: number, item: Plan) {
    return item.uuid
  }
}

type Plan = {
  uuid: string
  title: string
  subTitle?: string
  features: string[]
  price: string
}
