import { AbstractControl, ValidatorFn } from "@angular/forms"

/**
 * A regex that limits the email to 254 characters.
 * The prefix can have letters (a-z), numbers, underscores,
 * periods, and dashes. An underscore, period, or dash must
 * be followed by one or more letters or numbers.
 * The domain can have letters, numbers, dashes. The last
 * portion of the domain must be at least two characters.
 */
export const EMAIL_REGEX =
  /^(?=[A-Z0-9][A-Z0-9@._-]{5,253}$)[A-Z0-9._-]{1,64}[A-Z0-9]@(?:(?=[A-Z0-9-]{1,63}\.)[A-Z0-9]+(?:-[A-Z0-9]+)*\.){1,8}[A-Z]{2,63}$/iu

export function emailValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const valid = EMAIL_REGEX.test(control.value)
    return valid ? null : { invalidEmail: true }
  }
}
