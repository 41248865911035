<section class="p-section-spacing features bg-dark-green overflow-hidden px-4">
  <p
    class="text-heading-4 w-fit text-center mx-auto px-6 py-2 rounded-2xl bg-[rgba(255,_255,_255,_0.10)] [box-shadow:0px_3px_6px_0px_rgba(0,_0,_0,_0.25)] font-semibold"
    [innerHTML]="
      'features.title'
        | translate
          : {
              value:
                '<span class=text-white>Camp</span><span class=text-lime-green>rrr</span>'
            }
    "
  ></p>
  <div
    class="mt-[60px] flex flex-col justify-center gap-20 2xl:max-w-[1280px] max-w-full 2xl:min-w-[1280px] mx-[auto]"
  >
    <div
      *ngFor="let feature of features; trackBy: trackByFn; let even = even"
      class="px-8 py-7 rounded-[55px] bg-[rgba(255,_255,_255,_0.10)] [box-shadow:0px_3px_6px_0px_rgba(0,_0,_0,_0.25)] flex lg:flex-row flex-col lg:items-start sm:items-center gap-10"
      [ngClass]="{ 'lg:flex-row-reverse justify-between': even }"
    >
      <img
        [src]="feature.image"
        alt=""
        width="480"
        height="319"
        class="rounded-[14.513px]"
      />
      <div>
        <p class="lg:text-left text-center mb-6 font-normal text-heading-4">
          {{ feature.title | translate }}
        </p>
        <p
          class="lg:text-left text-center font-extralight max-w-[600px] text-heading-6"
        >
          {{ feature.description | translate }}
        </p>
      </div>
    </div>
  </div>
</section>
