import { Component, OnInit } from "@angular/core"
import { CommonModule } from "@angular/common"
import { TranslateModule, TranslateService } from "@ngx-translate/core"

@Component({
  selector: "app-features",
  standalone: true,
  imports: [CommonModule, TranslateModule],
  templateUrl: "./features.component.html",
  styleUrls: ["./features.component.css"],
})
export class FeaturesComponent implements OnInit {
  features: Feature[] = [
    {
      uuid: "fea373e5-263f-4089-b89c-0a45ad6fa004",
      image: "./assets/gifs/booking-form.gif",
      title: this.translate.instant("features.feature_1_title"),
      description: this.translate.instant("features.feature_1_desc"),
    },
    {
      uuid: "fea37215-263f-4089-b89c-0a45ad6fa004",
      image: "./assets/gifs/gantt.gif",
      title: this.translate.instant("features.feature_2_title"),
      description: this.translate.instant("features.feature_2_desc"),
    },
    {
      uuid: "fea373e5-24569-4089-b89c-0a45ad6fa004",
      image: "./assets/gifs/reports.gif",
      title: this.translate.instant("features.feature_3_title"),
      description: this.translate.instant("features.feature_3_desc"),
    },
    {
      uuid: "fea373e5-263f-4089-a14c-0a45ad6fa004",
      image: "./assets/gifs/reservation-tabs.gif",
      title: this.translate.instant("features.feature_4_title"),
      description: this.translate.instant("features.feature_4_desc"),
    },
    {
      uuid: "fea373e5-263f-4089-a14c-0a45ad6fa004",
      image: "./assets/gifs/rate-customer.gif",
      title: this.translate.instant("features.feature_5_title"),
      description: this.translate.instant("features.feature_5_desc"),
    },
  ]
  ngOnInit(): void {}
  constructor(private translate: TranslateService) {}
  trackByFn(index: number, item: Feature) {
    return item.uuid
  }
}

type Feature = {
  uuid: string
  image: string
  title: string
  description: string
}
