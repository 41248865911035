import { Injectable, Inject, PLATFORM_ID } from "@angular/core"
import { isPlatformBrowser } from "@angular/common"
import { BehaviorSubject, Observable } from "rxjs"

@Injectable({
  providedIn: "root",
})
export class LoadingService {
  private loadingSubject = new BehaviorSubject<boolean>(true)
  public loading$: Observable<boolean> = this.loadingSubject.asObservable()

  constructor(@Inject(PLATFORM_ID) private platformId: Object) {}

  setLoading(isLoading: boolean): void {
    this.loadingSubject.next(isLoading)
  }

  private handleAssetsLoaded(resolve: () => void): void {
    // Add a small delay to ensure all assets are fully rendered
    setTimeout(() => {
      this.setLoading(false)
      resolve()
    }, 500)
  }

  loadAssets(): Promise<void> {
    if (isPlatformBrowser(this.platformId)) {
      return new Promise<void>((resolve) => {
        if (document.readyState === "complete") {
          this.handleAssetsLoaded(resolve)
        } else {
          window.addEventListener("load", () =>
            this.handleAssetsLoaded(resolve),
          )
        }
      }).then(() => this.loadFonts()) // Add this line
    } else {
      return Promise.resolve()
    }
  }

  private loadFonts(): Promise<void> {
    if (isPlatformBrowser(this.platformId) && "fonts" in document) {
      return document.fonts.ready.then(() => {
        console.log("Fonts have loaded.")
      })
    }
    return Promise.resolve()
  }
}
