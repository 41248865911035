<section class="p-section-spacing">
  <p
    class="text-heading-5 max-w-max pl-8 pr-4 py-2 mb-[93px] bg-white rounded-tr-[9px] rounded-br-[9px] text-dark-green font-normal leading-7"
  >
    {{ "about_us.title" | translate }}
  </p>
  <p class="sm:px-2 px-6 text-center text-[20px] font-extralight leading-7 whitespace-normal sm:whitespace-pre-line tracking-[-0.4px] mx-auto max-w-[900px]">
    {{ "about_us.subtitle" | translate }}
  </p>
  <div
    class="mt-10 flex justify-center items-center gap-[50px] min-w-[176px] flex-wrap flex-col sm:flex-row"
  >
    <div *ngFor="let person of people; trackBy: trackByFn" class="p-2">
      <img
        [ngSrc]="person.image"
        alt=""
        width="160"
        height="160"
        class="mb-[12px]"
      />
      <div class="name text-center mb-3 font-semibold">
        {{ person.name | translate }}
      </div>
      <div class="caption text-center px-2 font-extralight">
        {{ person.caption | translate }}
      </div>
    </div>
  </div>
</section>
