import { DOCUMENT, isPlatformBrowser } from "@angular/common"
import { Inject, Injectable, PLATFORM_ID } from "@angular/core"
import { TranslateService } from "@ngx-translate/core"
import { BehaviorSubject, Observable } from "rxjs"

@Injectable({
  providedIn: "root",
})
export class TranslationService {
  private currentLang = new BehaviorSubject<string>("en")
  private supportedLangs = ["en", "el"]
  private defaultLang = "en"
  private isBrowser: boolean

  constructor(
    private translate: TranslateService,
    @Inject(DOCUMENT) private document: Document,
    @Inject(PLATFORM_ID) platformId: Object,
  ) {
    this.isBrowser = isPlatformBrowser(platformId)
    this.initLanguage()
  }

  private initLanguage() {
    const initialLang = this.isBrowser
      ? this.getBrowserLanguage()
      : this.defaultLang
    this.setLanguage(initialLang)
  }

  private getBrowserLanguage(): string {
    if (!this.isBrowser) return this.defaultLang

    const fullLang =
      this.document.defaultView?.navigator.language || this.defaultLang
    const primaryLang = fullLang.split("-")[0]

    return this.supportedLangs.includes(primaryLang)
      ? primaryLang
      : this.defaultLang
  }

  setLanguage(lang: string) {
    const validLang = this.supportedLangs.includes(lang)
      ? lang
      : this.defaultLang

    if (this.isBrowser) {
      this.translate.use(validLang)
      this.document.documentElement.lang = validLang
    }

    this.currentLang.next(validLang)
  }

  getCurrentLang(): Observable<string> {
    return this.currentLang.asObservable()
  }

  getSupportedLangs(): string[] {
    return this.supportedLangs
  }
}
