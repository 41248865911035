import { Injectable } from "@angular/core"
import { IndividualConfig, ToastrService } from "ngx-toastr"

@Injectable({
  providedIn: "root",
})
export class NotificationService {
  constructor(private toastr: ToastrService) {}

  showSuccess(
    message?: string | undefined,
    title?: string | undefined,
    override?: Partial<IndividualConfig<any>> | undefined,
  ) {
    this.toastr.success(message, title, override)
  }

  showError(
    message?: string | undefined,
    title?: string | undefined,
    override?: Partial<IndividualConfig<any>> | undefined,
  ) {
    this.toastr.error(message, title, override)
  }
}
