<section
  class="p-section-spacing text-center h-full bg-[url('/assets/images/hero.webp')] bg-cover bg-no-repeat bg-center"
>
  <h1
    class="sm:text-heading-1 text-heading-2 text-center font-bold py-2 mb-[40px] sm:whitespace-pre-line whitespace-normal px-4 max-w-[900px] mx-auto"
  >
    {{ "welcome.title" | translate }}
  </h1>
  <p
    class="py-2 text-heading-5 leading-[33.6px] tracking-[-0.48px] sm:whitespace-pre-line whitespace-normal px-4 max-w-[900px] mx-auto"
    [innerHTML]="
      'welcome.subtitle'
        | translate
          : {
              value:
                '<span class=text-white>Camp</span><span class=text-lime-green>rrr</span>'
            }
    "
  ></p>
  <button
    class="sm:my-[100px] my-[50px] bg-white text-dark-green rounded-3xl px-[40px] py-[10px] text-xl font-normal w-fit border-2 border-white border-solid hover:bg-white/30 hover:text-lime-green hover:font-semibold tracking-[-0.24px] md:py-[10] md:px-[40px] md:text-2xl md:leading-[33.6px] md:tracking-[-0.48px] transition-all duration-100 ease-in-out"
    (click)="ngxSmartModalService.getModal('requestDemoForm').open()"
  >
    {{ "welcome.contact_us" | translate }}
  </button>
  <p class="text-heading-6 text-center font-extralight py-2 sm:pt-[80px] pt-[20px] mb-4">
    {{ "welcome.trust_us" | translate }}
  </p>
  <div class="relative max-w-[679px] md:mx-auto">
    <owl-carousel-o [options]="customOptions" #owlCar>
      <ng-container *ngFor="let site of campingSites">
        <ng-template carouselSlide>
          <div class="custom-slide">
            <div
              class="max-h-[112px] max-w-[112px] bg-white bg-opacity-25 rounded-2xl p-4"
            >
              <img
                [src]="site.logo"
                [alt]="site.name"
                width="80"
                height="80"
                class="max-w-[80px]"
              />
            </div>
          </div>
        </ng-template>
      </ng-container>
    </owl-carousel-o>
  </div>
</section>

<ngx-smart-modal #requestDemoForm identifier="requestDemoForm">
  <app-request-form></app-request-form>
</ngx-smart-modal>
