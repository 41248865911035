import { Component, Inject, PLATFORM_ID } from "@angular/core"
import { CommonModule, isPlatformBrowser } from "@angular/common"
import { NgxSmartModalModule, NgxSmartModalService } from "ngx-smart-modal"
import { RequestFormComponent } from "../request-form/request-form.component"
import { TranslateModule } from "@ngx-translate/core"
import { CarouselModule, OwlOptions } from "ngx-owl-carousel-o"
import { debounceTime, fromEvent, Subject, takeUntil } from "rxjs"

@Component({
  selector: "app-welcome",
  standalone: true,
  imports: [
    CommonModule,
    NgxSmartModalModule,
    RequestFormComponent,
    CarouselModule,
    TranslateModule,
  ],
  templateUrl: "./welcome.component.html",
  styleUrls: ["./welcome.component.css"],
})
export class WelcomeComponent {
  windowWidth!: number
  private destroy$ = new Subject<void>()
  private isBrowser: boolean

  campingSites = [
    { name: "chorefto", logo: "./assets/clients/chorefto.webp" },
    { name: "rovies", logo: "./assets/clients/rovies.webp" },
    { name: "thines", logo: "./assets/clients/thines.webp" },
    { name: "lefka", logo: "./assets/clients/lefka.webp" },
  ]
  customOptions: OwlOptions = {
    mergeFit: true,
    loop: true,
    mouseDrag: false,
    touchDrag: false,
    pullDrag: false,
    dots: false,
    slideBy: 2,
    navSpeed: 600,
    navText: ["", ""],
    nav: false,
    items: 3,
    margin: 40,
    stagePadding: 1,
    responsive: {
      0: {
        items: 2,
        autoplay: true,
        stagePadding: 79,
      },
      435: {
        items: 2,
      },
      679: {
        items: 4,
      },
    },
  }

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    public ngxSmartModalService: NgxSmartModalService,
  ) {
    this.isBrowser = isPlatformBrowser(this.platformId)
  }

  ngOnInit() {
    if (this.isBrowser) {
      this.getWindowWidth()
      this.onResize()
    }
  }

  ngOnDestroy() {
    this.destroy$.next()
    this.destroy$.complete()
  }

  private getWindowWidth() {
    this.windowWidth = window.innerWidth
    this.updateCarouselOptions()
  }

  private onResize() {
    fromEvent(window, "resize")
      .pipe(debounceTime(300), takeUntil(this.destroy$))
      .subscribe(() => {
        this.windowWidth = window.innerWidth
        console.log("Updated window width:", this.windowWidth)
        this.updateCarouselOptions()
      })
  }

  private updateCarouselOptions() {
    if ((this, this.windowWidth >= 564)) {
      this.customOptions = { ...this.customOptions, slideBy: 2 } // this will make the carousel refresh
    } else if (this.windowWidth < 564) {
      this.customOptions = { ...this.customOptions, slideBy: 1 } // this will make the carousel refresh
    }
  }
}
