<nav
  class="flex items-center justify-between text-xs pt-6 px-4 pb-4.5 fixed w-full z-20 transition-[background-color]"
  [ngClass]="transparentNav ? '' : 'bg-dark-green shadow-md'"
>
  <div id="logo" class="font-camprrr text-heading-6 hidden sm:block">
    camp<span class="text-lime-green">rrr</span>
  </div>
  <div class="sm:hidden block">
    <ng-container [ngTemplateOutlet]="mobileLogoIcon"></ng-container>
  </div>
  <div class="flex items-center sm:gap-6 gap-4">
    <div class="language-selector-wrapper">
      <div
        class="language-selector"
        (click)="toggleLanguage()"
        [class.open]="isDropdownOpen"
        (clickOutside)="onClickOutside()"
      >
        <div class="selected-language flex items-center gap-3 w-full px-3 py-2">
          <span class="text-body w-[50px]">{{
            getLanguageLabel(currentLang)
          }}</span>
          <ng-container [ngTemplateOutlet]="arrowIcon"></ng-container>
        </div>
        <div
          class="other-language text-body"
          (click)="selectLanguage(getOtherLanguage()); $event.stopPropagation()"
        >
          {{ getLanguageLabel(getOtherLanguage()) }}
        </div>
      </div>
    </div>
    <button
      class="bg-white border border-white text-dark-green py-[8px] px-5 rounded-xl text-body font-semibold leading-[16.8px] tracking-[-0.24px] uppercase hover:bg-white/30 hover:text-lime-green hover:font-semibold transition-all duration-100 ease-in-out"
      (click)="ngxSmartModalService.getModal('requestDemoForm').open()"
    >
      {{ "navbar.request_a_demo" | translate }}
    </button>
  </div>
</nav>

<ngx-smart-modal #requestDemoForm identifier="requestDemoForm">
  <app-request-form></app-request-form>
</ngx-smart-modal>

<ng-template #arrowIcon>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19 8.5L13.5 13.5L8 8.5"
      stroke="white"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</ng-template>

<ng-template #mobileLogoIcon>
  <svg
    width="34"
    height="34"
    viewBox="0 0 34 34"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.5086 1.86347C21.0005 1.86593 24.3842 3.0753 27.0866 5.28673C29.789 7.49817 31.644 10.5757 32.3373 13.9981C33.0306 17.4205 32.5196 20.9773 30.8909 24.0662C29.2622 27.155 26.616 29.5859 23.4003 30.9472C19.7059 32.4844 15.5531 32.4965 11.8497 30.981C8.14631 29.4654 5.1935 26.5454 3.63667 22.8592C2.07984 19.1729 2.04562 15.0203 3.54148 11.3089C5.03734 7.59749 7.94162 4.6292 11.6195 3.0528C13.4832 2.26391 15.487 1.85939 17.5107 1.86347M17.5107 1C8.67447 1 1.51074 8.16319 1.51074 17C1.51074 25.8368 8.67447 33 17.5107 33C26.347 33 33.5107 25.8368 33.5107 17C33.5107 8.16319 26.3454 1 17.5086 1H17.5107Z"
      fill="#99FF66"
    />
    <path
      d="M7.94686 14.9525C7.65246 15.601 7.45459 16.457 7.45459 17.3391V21.0602C7.45459 21.3892 7.58529 21.7047 7.81793 21.9374C8.05058 22.17 8.36611 22.3007 8.69512 22.3007C9.02413 22.3007 9.33967 22.17 9.57231 21.9374C9.80496 21.7047 9.93565 21.3892 9.93565 21.0602V17.3391C9.93565 15.2106 11.4386 14.8858 13.2247 14.8149C13.9074 14.7877 14.4652 14.2959 14.4652 13.6175C14.4652 12.9391 13.9031 12.4442 13.2247 12.4442C12.0263 12.4442 11.0589 12.6309 10.5047 12.8469C10.0338 13.0314 9.47112 13.2314 8.85139 13.7599C8.31806 14.2207 8.12659 14.5557 7.94686 14.9525Z"
      fill="#99FF66"
    />
    <path
      d="M14.9576 14.9525C14.6632 15.601 14.4653 16.457 14.4653 17.3391V21.0602C14.4653 21.3892 14.596 21.7047 14.8287 21.9374C15.0613 22.17 15.3769 22.3007 15.7059 22.3007C16.0349 22.3007 16.3504 22.17 16.5831 21.9374C16.8157 21.7047 16.9464 21.3892 16.9464 21.0602V17.3391C16.9464 15.2106 18.4493 14.8858 20.2355 14.8149C20.9181 14.7877 21.476 14.2959 21.476 13.6175C21.476 12.9391 20.9139 12.4442 20.2355 12.4442C19.0365 12.4442 18.0696 12.6309 17.5155 12.8469C17.0451 13.0314 16.4824 13.2314 15.8621 13.7599C15.3288 14.2207 15.1395 14.5557 14.9576 14.9525Z"
      fill="#99FF66"
    />
    <path
      d="M21.9662 14.9525C21.6718 15.601 21.4739 16.457 21.4739 17.3391V21.0602C21.4705 21.2252 21.5 21.3893 21.5608 21.5428C21.6216 21.6962 21.7124 21.836 21.8279 21.954C21.9434 22.0719 22.0813 22.1656 22.2335 22.2296C22.3857 22.2935 22.5491 22.3265 22.7142 22.3265C22.8792 22.3265 23.0427 22.2935 23.1948 22.2296C23.347 22.1656 23.4849 22.0719 23.6004 21.954C23.7159 21.836 23.8067 21.6962 23.8675 21.5428C23.9283 21.3893 23.9579 21.2252 23.9544 21.0602V17.3391C23.9544 15.2106 25.4579 14.8858 27.2435 14.8149C27.9262 14.7877 28.484 14.2959 28.484 13.6175C28.484 12.9391 27.9219 12.4442 27.2435 12.4442C26.0451 12.4442 25.0776 12.6309 24.5235 12.8469C24.0531 13.0314 23.4904 13.2314 22.8702 13.7599C22.3395 14.2207 22.148 14.5557 21.9662 14.9525Z"
      fill="#99FF66"
    />
  </svg>
</ng-template>
