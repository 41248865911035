import { AfterContentInit, Component, OnDestroy, OnInit } from "@angular/core"
import { CommonModule } from "@angular/common"
import {
  FormBuilder,
  ReactiveFormsModule,
  Validators,
  NonNullableFormBuilder,
} from "@angular/forms"
import { ApiService } from "src/app/services/api.service"
import { NotificationService } from "src/app/services/notification/notification.service"
import { NgxSmartModalService } from "ngx-smart-modal"
import { TranslateModule, TranslateService } from "@ngx-translate/core"
import { emailValidator } from "src/app/utils/emaiil-validator"

@Component({
  selector: "app-request-form",
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, TranslateModule],
  templateUrl: "./request-form.component.html",
  styleUrls: ["./request-form.component.css"],
})
export class RequestFormComponent implements OnInit, AfterContentInit {
  disableSubmitButton = false

  requestDemoForm = this.nnfb.group({
    name: ["", Validators.required],
    email: ["", emailValidator()],
    campingName: [""],
    phone: [""],
    message: [""],
  })

  constructor(
    private nnfb: NonNullableFormBuilder,
    private api: ApiService,
    private notification: NotificationService,
    public ngxSmartModalService: NgxSmartModalService,
    private translate: TranslateService,
  ) {}

  ngOnInit(): void {}

  ngAfterContentInit(): void {
    // this.ngxSmartModalService
    //   .get("requestDemoForm")
    //   .onAnyCloseEventFinished.subscribe(() => {
    //     this.resetForm()
    //   })
  }

  resetForm(): void {
    this.requestDemoForm.reset()
    this.requestDemoForm.controls["name"].setErrors(null)
    this.requestDemoForm.controls["email"].setErrors(null)
  }

  onSubmit() {
    if (this.disableSubmitButton || this.requestDemoForm.invalid) return

    this.disableSubmitButton = true
    this.api
      .sendEmail({
        ...this.requestDemoForm.value,
        lang: this.translate.currentLang,
      })
      .subscribe({
        next: (res: any) => {
          this.disableSubmitButton = false
          this.notification.showSuccess(
            this.translate.instant("request_form.messages.success_description"),
            this.translate.instant("request_form.messages.success_title"),
          )
          this.ngxSmartModalService.get("requestDemoForm").close()
        },
        error: (err: any) => {
          this.disableSubmitButton = false
          this.notification.showError(
            this.translate.instant("request_form.messages.error_description"),
            this.translate.instant("request_form.messages.error_title"),
          )
          this.ngxSmartModalService.get("requestDemoForm").close()
        },
        complete: () => {
          console.log("complete")
        },
      })
  }
}
