<form
  [formGroup]="requestDemoForm"
  (ngSubmit)="onSubmit()"
  class="flex flex-col sm:gap-10 gap-5"
>
  <h4 class="text-center font-normal">
    {{ "request_form.title" | translate }}
  </h4>
  <p class="text-center font-extralight text-heading-6">
    {{ "request_form.subtitle" | translate }}
  </p>
  <div class="flex sm:flex-row flex-col sm:gap-[50px] gap-5">
    <div class="flex flex-col gap-1 w-full">
      <label for="name" class="ml-3">{{
        "request_form.name" | translate
      }}</label>
      <input
        id="name"
        type="text"
        formControlName="name"
        class="input"
        placeholder="{{ 'request_form.name_placeholder' | translate }}"
      />
    </div>
    <div class="flex flex-col gap-1 w-full">
      <label for="email" class="ml-3"
        >{{ "request_form.email" | translate }}
      </label>
      <input
        id="email"
        type="text"
        formControlName="email"
        class="input"
        placeholder="{{ 'request_form.email_placeholder' | translate }}"
      />
    </div>
  </div>
  <div class="flex sm:flex-row flex-col sm:gap-[50px] gap-5">
    <div class="flex flex-col gap-1 w-full">
      <label for="campingName" class="ml-3">{{
        "request_form.camping_name" | translate
      }}</label>
      <input
        id="campingName"
        type="text"
        formControlName="campingName"
        class="input"
        placeholder="{{ 'request_form.camping_name_placeholder' | translate }}"
      />
    </div>
    <div class="flex flex-col gap-1 w-full">
      <label for="phone" class="ml-3">{{
        "request_form.phone" | translate
      }}</label>
      <input
        id="phone"
        type="text"
        formControlName="phone"
        class="input"
        placeholder="{{ 'request_form.phone_placeholder' | translate }}"
      />
    </div>
  </div>
  <div class="flex flex-col gap-1">
    <label for="message" class="ml-3">{{
      "request_form.comments" | translate
    }}</label>
    <textarea
      id="message"
      name="message"
      formControlName="message"
      rows="4"
      cols="50"
      class="input"
      placeholder="{{ 'request_form.comments_placeholder' | translate }}"
    ></textarea>
  </div>
  <button
    type="submit"
    class="bg-dark-green text-white py-2 px-4 border border-dark-green mt-4 rounded-2xl uppercase hover:border-lime-green hover:text-lime-green hover:font-semibold transition-all duration-100 ease-in-out"
    [disabled]="disableSubmitButton"
  >
    {{ "request_form.send" | translate }}
  </button>
</form>
