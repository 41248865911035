<section class="workflow p-section-spacing">
  <div class="text-center">
    <div class="flex items-center justify-center">
      <div class="cloud relative">
        <img
          src="./assets/icons/cloud.svg"
          alt=""
          width="360.41px"
          height="251px"
        />
        <img
          src="./assets/icons/logo.svg"
          alt=""
          class="absolute top-[45px] left-[88px]"
        />
        <p
          class="p-[12.9px] absolute bottom-[67.13px] left-[30.57px] right-[30.57px] text-[25.744px] leading-[36.041px] tracking-[-0.515px]"
        >
          {{ "workflow.title" | translate }}
        </p>
      </div>
    </div>
  </div>

  <div
    class="pt-[80px] flex flex-wrap flex-col items-center justify-center gap-x-[60px] gap-y-8 px-16"
  >
    <div class="flex gap-2 sm:items-center items-start" *ngFor="let flowItem of flowItems; trackBy: trackByFn">
      <ng-container [ngTemplateOutlet]="checkIcon"></ng-container>
      <p class="text-center text-heading-6">
        {{ flowItem.text | translate }}
      </p>
    </div>
  </div>
  <div
    class="mt-[80px] w-full h-[400px] max-w-3000px min-w-1400px ml-50 translate-50"
  >
    <ng-lottie [options]="options"></ng-lottie>
  </div>
</section>
<ng-template #checkIcon>
  <svg
    class="flex-shrink-0 sm:mt-0 mt-2"
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.7367 1.03132L4.52651 11.4652L1.17787 7.67606C0.623833 7.04863 -0.248679 8.001 0.303373 8.62947L4.11726 12.9446C4.17546 13.0044 4.24531 13.0517 4.3225 13.0836C4.39969 13.1154 4.48258 13.1311 4.56606 13.1296C4.64954 13.1281 4.73184 13.1096 4.80787 13.0751C4.8839 13.0406 4.95206 12.9909 5.00813 12.929L13.6449 1.95361C14.1596 1.29952 13.2552 0.372166 12.7367 1.03132Z"
      fill="#99FF66"
    />
  </svg>
</ng-template>
