<div *ngIf="loading$ | async"></div>
<div [style.visibility]="(loading$ | async) ? 'hidden' : 'visible'">
  <app-navbar></app-navbar>
  <app-welcome></app-welcome>
  <app-features></app-features>
  <app-workflow></app-workflow>
  <app-pricing></app-pricing>
  <!-- <app-early-access></app-early-access> -->
  <!-- <app-clients></app-clients> -->
  <app-my-data></app-my-data>
  <app-about-us></app-about-us>
  <app-footer></app-footer>
</div>
