import { Component } from "@angular/core"
import { CommonModule } from "@angular/common"
import {
  animate,
  keyframes,
  style,
  transition,
  trigger,
  state,
} from "@angular/animations"
import { Toast } from "ngx-toastr"

@Component({
  selector: "app-notification",
  standalone: true,
  imports: [CommonModule],
  templateUrl: "./notification.component.html",
  styleUrls: ["./notification.component.css"],
  animations: [
    trigger("flyInOut", [
      state(
        "inactive",
        style({
          opacity: 0,
        }),
      ),
      transition(
        "inactive => active",
        animate(
          "400ms ease-out",
          keyframes([
            style({
              // transform: 'translate3d(100%, 0, 0) skewX(-30deg)',
              transform: "translate3d(100%, 0, 0)",
              opacity: 0,
            }),

            style({
              transform: "none",
              opacity: 1,
            }),
          ]),
        ),
      ),
      transition(
        "active => removed",
        animate(
          "400ms ease-out",
          keyframes([
            style({
              opacity: 1,
            }),
            style({
              transform: "translate3d(100%, 0, 0)",
              opacity: 0,
            }),
          ]),
        ),
      ),
    ]),
  ],
  preserveWhitespaces: false,
})
export class NotificationComponent extends Toast {}
