<section class="pb-[72px]">
  <h1
    class="py-2 pl-8 pr-4 bg-white rounded-tr-[9px] rounded-br-[9px] text-dark-green text-section-title w-max"
  >
    {{ "pricing.title" | translate }}
  </h1>
  <div class="flex justify-center flex-wrap gap-[83px] sm:my-[100px] my-[80px]">
    <div
      class="relative min-w-[296px] w-[296px]"
      *ngFor="let plan of plans; trackBy: trackByFn"
    >
      <div
        class="title z-10 absolute top-[-41px] px-5 py-2 ml-4 bg-lime-green rounded-[22px] text-dark-green text-[32px] font-semibold leading-[44.80px] w-max"
      >
        {{ plan.title | translate }}
      </div>
      <div
        class="content px-4 py-10 bg-[linear-gradient(180deg,rgba(255,255,255,0.62),_#366)] rounded-2xl"
        [ngClass]="plan.title === 'Plus' ? 'blur-sm select-none' : ''"
      >
        <div class="text-dark-green font-bold tracking-[-0.4px] mb-2">
          {{ plan.subTitle || "" | translate }}
        </div>
        <ul class="list-disc pl-5">
          <li
            *ngFor="let feature of plan.features"
            class="text-xl font-normal py-3.5"
          >
            {{ feature | translate }}
          </li>
        </ul>
      </div>
      <div
        class="price mt-2 bg-[linear-gradient(180deg,rgba(182,124,255,0.20)0%,rgba(220,192,255,0.12)41.67%,rgba(255,255,255,0.03)100%)] px-2 py-4 rounded-2xl text-center"
      >
        <span class="font-normal text-heading-4">
          {{
            plan.price !== "Soon..."
              ? plan.price + ("pricing.yearly" | translate)
              : plan.price
          }}
        </span>
        <div class="leading-8 font-extralight">
          {{ plan.price !== "Soon..." ? ("pricing.exl_vat" | translate) : "" }}
        </div>
      </div>
    </div>
  </div>
  <div class="px-8 flex flex-col items-center">
    <p
      class="sm:pb-[40px] pb-[80px] text-heading-5 text-center font-normal sm:whitespace-pre-line whitespace-normal tracking-[-0.4px]"
    >
      {{ "pricing.contact_us_header" | translate }}
    </p>
    <button
      (click)="ngxSmartModalService.getModal('requestDemoForm').open()"
      target="_blank"
      class="bg-white text-dark-green rounded-3xl h6 px-[40px] py-[10px] text-xl font-normal w-fit border-2 border-white border-solid hover:bg-white/30 hover:text-lime-green hover:font-semibold transition-all duration-100 ease-in-out"
    >
      {{ "pricing.contact_us" | translate }}
    </button>
  </div>
</section>

<ngx-smart-modal #requestDemoForm identifier="requestDemoForm">
  <app-request-form></app-request-form>
</ngx-smart-modal>
