import {
  ApplicationConfig,
  importProvidersFrom,
  APP_INITIALIZER,
} from "@angular/core"
import { NgxSmartModalModule } from "ngx-smart-modal"
import { provideHttpClient, HttpClient } from "@angular/common/http"
import { provideAnimations } from "@angular/platform-browser/animations"
import { TranslateHttpLoader } from "@ngx-translate/http-loader"
import { provideToastr } from "ngx-toastr"
import { NotificationComponent } from "./components/notification/notification.component"
import { provideLottieOptions } from "ngx-lottie"
import { TranslateLoader, TranslateModule } from "@ngx-translate/core"
import { LoadingService } from "./services/loading/loading.service"

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(
    http,
    "./assets/i18n/",
    ".json?cb=" + new Date().getTime(),
  )
}

export const appConfig: ApplicationConfig = {
  providers: [
    importProvidersFrom(NgxSmartModalModule.forRoot()),
    provideHttpClient(),
    provideAnimations(),
    provideToastr({
      toastComponent: NotificationComponent,
      closeButton: true,
      toastClass: "",
      titleClass: "",
      messageClass: "",
      preventDuplicates: true,
      tapToDismiss: true,
    }),
    importProvidersFrom([
      TranslateModule.forRoot({
        loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient],
        },
      }),
    ]),
    provideLottieOptions({
      player: () => import("lottie-web"),
    }),
    LoadingService,
    {
      provide: APP_INITIALIZER,
      useFactory: (loadingService: LoadingService) => () =>
        loadingService.loadAssets(),
      deps: [LoadingService],
      multi: true,
    },
  ],
}
